import React, { useEffect, useState } from 'react'

import { Table, Button, Input, Select, Flex, Tag, Tooltip, notification } from 'antd';
import {
  EditOutlined,
  PlusOutlined,
  MinusOutlined,
  SearchOutlined,
  CheckOutlined,
  CloseOutlined,
  StockOutlined,
  UnlockOutlined,
  LockOutlined
} from '@ant-design/icons';

import { REQUESTS } from '../../api/requests';

import dateFormat from '../../utils/dateFormat';

import useStore from '../../hooks/use-store';

import ResellerDrawer from './components/ResellerDrawer';

import CreditsDrawer from './components/CreditsDrawer';

import filterDropdown from '../../components/FilterDropdown';

import ActionDropdown from '../../components/ActionDropdown';

import useResize from "../../hooks/use-resize";

import ResellerDevices from './components/ResellerDevices';

import PageTemplate from '../../components/PageTemplate';

import ResellerDNSStatisticModal from './components/ResellerDNSStatisticModal';

import ConfirmModal from '../../utils/ConfirmModal';

import styles from "./index.module.scss";

export default function ResellersComponent() {
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [defaultType, setDefaultType] = useState("reseller");

  const [open, setOpen] = useState(false);
  const [creditUpdate, setCreditUpdate] = useState(false);
  const [showStatistic, setShowStatistic] = useState(false);

  const [current, setCurrent] = useState(null);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(["createdAt", "DESC"]);
  const [date, setDate] = useState("");
  const [filter, setFilter] = useState({});
  const [showDevices, setShowDevices] = useState(false);
  const [profile] = useStore("profile");

  const windowSize = useResize()

  const handeAction = (e, record) => {
    switch (e.key) {
      case "Edit":
        setCurrent(record);
        setOpen(true);
        break;
      case "Add Credit":
        setCurrent(record);
        setCreditUpdate("Add Credit");
        break;
      case "Take Credit":
        setCurrent(record);
        setCreditUpdate("Take Credit");
        break;
      case "is_partner":
        setShowStatistic(true);
        setCurrent(record);
        break;
      case "Block":
        ConfirmModal(`Are you sure you want to ${record.blocked ? "unblock" : "block"} ${record.name}?`, () => blockReseller(record))
        break;

      default:
        break;
    }
  }

  const columns = [
    {
      title: '#',
      width: 50,
      dataIndex: 'id',
      key: 'id',
      render: (text, record, index) => {
        return (currentPage - 1) * limit + index + 1
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: true
    },
    {
      title: 'Credits',
      width: 100,
      align: "center",
      dataIndex: 'credits',
      sorter: true
    },
    {
      title: 'Activated devices',
      align: "center",
      dataIndex: 'device_count',
      sorter: true
    },
    {
      title: 'Remark',
      align: "center",
      dataIndex: 'remark',
      ellipsis: {
        showTitle: false,
      },
      sorter: true,
      render: (text, record) => (
        <Tooltip placement="topLeft" title={record?.remark}>
          {record?.remark}
        </Tooltip>
      ),
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      align: "center",
      sorter: true,
      ...filterDropdown(setDate),
      render: (text, record) => record.createdAt ? dateFormat(record.createdAt) : "-"
    },
    {
      title: 'Type',
      align: "center",
      dataIndex: 'parent_id',
      sorter: true,
      render: (text, record) => record.parent_id ? "Sub-reseller" : "Reseller"
    },
    {
      title: 'Partner',
      width: 100,
      align: "center",
      dataIndex: 'is_partner',
      sorter: true,
      render: (text, record) => record.is_partner ? <CheckOutlined style={{ color: "green" }} /> : <CloseOutlined style={{ color: "red" }} />
    },
    {
      title: 'Free DNS Price',
      align: "center",
      dataIndex: 'free_dns_price',
      render: (text, record) => record.free_dns_price
    },
    {
      title: 'Status',
      width: 100,
      align: "center",
      dataIndex: 'blocked',
      sorter: true,
      filters: [
        {
          text: 'Active',
          value: false,
        },
        {
          text: 'Blocked',
          value: true,
        }
      ],
      filterMultiple: false,
      render: (text, record) => {
        return record.blocked ?
          <Tag color={'red'}>
            Blocked
          </Tag> :
          <Tag color={"green"} >
            Active
          </Tag>
      }
    },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      align: "center",
      render: (text, record) => (
        <ActionDropdown
          items={record ? [
            {
              key: "Edit",
              label: <div >
                <EditOutlined style={{ cursor: "pointer" }} /> Edit
              </div>,
              onClick: (e) => handeAction(e, record)
            },
            {
              key: "Block",
              label: <div >
                {record.blocked ? <><UnlockOutlined /> Unblock</> : <><LockOutlined style={{ cursor: "pointer" }} /> Block</>}
              </div>,
              onClick: (e) => handeAction(e, record)
            },
            record.parent_id ? null : {
              key: "Add Credit",
              label: <div>
                <PlusOutlined style={{ cursor: "pointer" }} /> Add Credit
              </div>,
              onClick: (e) => handeAction(e, record)
            },
            record.parent_id ? null : {
              key: "Take Credit",
              label: <div>
                <MinusOutlined style={{ cursor: "pointer" }} /> Take Credit
              </div>,
              onClick: (e) => handeAction(e, record)
            },
            record.is_partner && {
              key: "is_partner",
              label: <div>
                <StockOutlined style={{ cursor: "pointer" }} /> Free DNS Statistic
              </div>,
              onClick: (e) => handeAction(e, record)
            },
          ] : []
          }
        />
      ),
    },
  ];

  const blockReseller = (record) => {
    const body = {
      id: record.id,
      blocked: !record.blocked
    };

    REQUESTS.RESELLER.BLOCK(body, (data) => {

      getResellers();

      notification.success({
        message: "Success",
      })
    })
  };

  const getResellers = () => {
    setLoading(true)

    const params = {
      limit: limit,
      page: currentPage,
      sort,
    }

    if (search.length) {
      params.search = {
        name: search,
        email: search
      }
    }

    if (filter["blocked"]) {
      params["filter"] = {
        blocked: filter["blocked"][0]
      }
    }

    if (defaultType == "reseller") {
      params["filter"] = {
        parent_id: null
      }
    } else {
      params["filter_notin"] = {
        parent_id: ["null"]
      }
    }

    const dateFilter = [
      { date: date, between: "createdAt" },
    ]

    dateFilter.forEach((item) => {
      if (item.date.length && item.date[0] && item.date[1]) {
        params['between'] = {
          [item.between]: {
            from: item.date[0] + " 00:00",
            to: item.date[1] + " 23:59"
          }
        }
      }
    })

    REQUESTS.RESELLER.GET({ query: JSON.stringify(params) }, (data) => {
      setLoading(false)
      setLimit(data.limit)
      setTotal(data.count)
      setDataSource(data.rows)
    }, (error) => {
      setLoading(false)
    })
  }

  const handleOnChange = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current)
    setLimit(pagination.pageSize)
    setFilter(filters)
    if (sorter.field && sorter.order) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    } else {
      setSort(["createdAt", "DESC"])
    }
  }

  useEffect(() => {
    if (!Object.keys(profile).length) return;

    let interval = setTimeout(getResellers, 1000);

    return () => {
      clearTimeout(interval)
    }
  }, [currentPage, limit, search, filter, sort, date, profile, defaultType])

  return (
    <PageTemplate title="Resellers">
      <Flex gap={20} justify='space-between' style={{ marginBottom: 20 }} wrap='wrap'>
        <Flex gap={10} wrap='wrap'>
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{ width: 300 }}
          />
          <Select
            defaultValue={defaultType}
            onChange={(item) => {
              setDefaultType(item)
            }}
            style={{ width: 130 }}
          >
            <Select.Option value="reseller">Reseller</Select.Option>
            <Select.Option value="sub-reseller">Sub-reseller</Select.Option>
          </Select>
        </Flex>
        <Button onClick={() => {
          setOpen(true)
          if (current) setCurrent(null);
        }} type="primary"><PlusOutlined /> Add Reseller</Button>
      </Flex>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        onChange={handleOnChange}
        rowClassName={(record, index) => {
          return record.blocked ? styles['blocked'] : ''
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: event => {
              setCurrent(record);
              setShowDevices(true);
            }
          }
        }}
        pagination={{
          current: currentPage,
          total: total,
          pageSize: limit,
          showSizeChanger: true,
        }}
        scroll={{ x: 'max-content' }}
        size={windowSize < 900 ? "small" : "large"}
      />
      <ResellerDrawer
        open={open}
        onClose={() => {
          setOpen(false);
          setCurrent(null)
        }}
        getResellers={getResellers}
        current={current}
      />
      <CreditsDrawer
        creditUpdate={creditUpdate}
        onClose={() => {
          setCreditUpdate(false);
          if (current) setCurrent(null);
        }}
        getResellers={getResellers}
        current={current}
      />

      <ResellerDevices
        current={current}
        open={showDevices}
        onClose={() => {
          setShowDevices(false);
          setCurrent(null)
        }}
      />
      <ResellerDNSStatisticModal
        reseller={current}
        open={showStatistic}
        onCancel={() => {
          setShowStatistic(false);
          setCurrent(null)
        }}
      />
    </PageTemplate>
  )
}
