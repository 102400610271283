import { useEffect, useState } from 'react';

import { Drawer, Table, Flex, Input } from 'antd';

import { SearchOutlined } from '@ant-design/icons';

import { REQUESTS } from '../../../api/requests';

import useStore from '../../../hooks/use-store';

import dateFormat from '../../../utils/dateFormat';

import ValidateMacAddress from '../../../components/ValidateMacAddress';

import filterDropdown from '../../../components/FilterDropdown';

export default function ResellerDevices({ current, open, onClose }) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [date, setDate] = useState("");
    const [expDate, setExpDate] = useState("");
    const [sort, setSort] = useState(["createdAt", "DESC"]);
    const [mac, setMac] = useState("");
    const [search, setSearch] = useState("");
    const [filter, setFilter] = useState({});

    const [apps] = useStore("apps");

    const columns = [
        {
            title: "Device ID",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Mac Address",
            dataIndex: "mac",
            key: "mac",
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
                return <ValidateMacAddress value={mac} setValue={setMac} />
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
        },
        {
            title: "Model",
            dataIndex: "model",
            key: "model",
            sorter: true,
            filters: [
                {
                    text: 'Tizen',
                    value: "tizen",
                },
                {
                    text: 'WebOS',
                    value: "webos",
                },
                {
                    text: 'Android',
                    value: "android",
                },
                {
                    text: 'Roku',
                    value: "roku",
                },
                {
                    text: 'Vidaa',
                    value: "vidaa",
                },
                {
                    text: 'Zeasn',
                    value: "zeasn",
                },
                {
                    text: 'Vizio',
                    value: "vizio",
                },
                {
                    text: 'TVOS',
                    value: "tvos",
                },
                {
                    text: 'IOS',
                    value: "ios",
                },
                {
                    text: 'Foxxum',
                    value: "foxxum",
                },
            ],
            filterMultiple: false,
        },
        {
            title: "App Name",
            dataIndex: "app_name",
            key: "app_name",
            sorter: true,
            filters: apps.map(app => ({ text: app.name, value: app.name })),
            filterMultiple: false,
        },
        {
            title: "Country",
            dataIndex: "country",
            key: "country",
        },
        {
            title: "IP",
            dataIndex: "ip",
            key: "ip",
        },
        {
            title: "Expired Date",
            dataIndex: "activation_expired",
            key: "activation_expired",
            sorter: true,
            ...filterDropdown(setExpDate),
            render: (text) => {
                return text ? dateFormat(text) : "-"
            }
        },
        {
            title: "Created Date",
            dataIndex: "createdAt",
            key: "createdAt",
            sorter: true,
            ...filterDropdown(setDate),
            render: (text) => {
                return text ? dateFormat(text) : "-"
            }
        },
    ];

    const getData = () => {
        setLoading(true);

        const query = {
            limit,
            page: currentPage,
            filter: {
                reseller_id: current.id,
            },
            sort,
        };

        if (mac.length) {
            query["search"] = {
                mac,
            }
        }

        if (filter["model"]) {
            query.filter = {
                ...query.filter,
                model: filter["model"][0]
            }
        }

        if (filter["app_name"]) {
            query.filter = {
                ...query.filter,
                app_name: filter["app_name"][0]
            }
        }

        if (search.length) {
            query["search"] = {
                ip: search,
                country: search,
                model: search,
                app_name: search,
            }
        }

        const dateFilter = [
            { date: date, between: "createdAt" },
            { date: expDate, between: "activation_expired" },
        ]

        dateFilter.forEach((item) => {
            if (item.date.length && item.date[0] && item.date[1]) {
                query['between'] = {
                    [item.between]: {
                        from: item.date[0] + " 00:00",
                        to: item.date[1] + " 23:59"
                    }
                }
            }
        })

        const callback = (data) => {
            setLoading(false);
            setTotal(data.count);
            // setLimit(data.limit);
            setData(data.rows)
        }

        const errorCallback = (err) => {
            setLoading(false)
        }

        REQUESTS.RESELLER_DEVICES({ query: JSON.stringify(query) }, callback, errorCallback)
    }

    const handleOnChange = (pagination, filters, sorter) => {
        setCurrentPage(pagination.current);
        setLimit(pagination.pageSize);
        setFilter(filters)
        if (sorter.field && sorter.order) {
            setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
        } else {
            setSort(["createdAt", "DESC"])
        }
    }

    useEffect(() => {
        if (!current) return;

        let timer = setTimeout(getData, 500);

        return () => {
            return () => clearTimeout(timer)
        }
    }, [current, open, limit, currentPage, sort, date, expDate, mac, search]);

    useEffect(() => {
        if (!open) {
            setCurrentPage(1);
            setLimit(10);
            setSort(["createdAt", "DESC"]);
            setDate("");
            setExpDate("");
            setMac("")
            setSearch("")
        }
    }, [open])

    return (
        <Drawer
            title="Devices"
            open={open}
            onClose={onClose}
            width={"90%"}
            forceRender={true}
            destroyOnClose={true}
        >
            <Flex justify='space-between' gap={10}>
                <Input
                    prefix={<SearchOutlined />}
                    placeholder='Search'
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    style={{ width: 300, marginBottom: 10 }}
                />
                {/* <Button onClick={() => setOpenExportModal(true)}>Export to CSV</Button> */}
            </Flex>
            <Table
                rowKey="id"
                columns={columns}
                dataSource={data}
                loading={loading}
                onChange={handleOnChange}
                pagination={{
                    current: currentPage,
                    total: total,
                    pageSize: limit,
                    showSizeChanger: true,
                }}
                scroll={{ x: 1000 }}
                size="small"
            />
        </Drawer>
    )
}
