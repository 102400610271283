import { HOST, NOT_HOST } from "../config";

export const URLS = {
    VERSION: `${HOST}version`,
    AUTH: {
        RESELLER_LOGIN: `${HOST}reseller/login`,
        ADMIN_LOGIN: `${HOST}admin/login`,
    },
    RECAPCHA_SITE_KEY: `${HOST}key`,
    PROFILE: `${HOST}admin/profile`,
    RESELLER: `${HOST}admin/reseller`,
    CREDITS: {
        ADD: `${HOST}admin/reseller/add/credit`,
        TAKE: `${HOST}admin/reseller/take/credit`,
    },
    RESELLER_DEVICES: `${HOST}admin/devices`,
    APPS: `${HOST}admin/apps`,
    FREE_DNS: `${HOST}admin/free_dns`,
    FREE_DNS_STATISTICS_ALL: `${HOST}admin/free_dns/statistics/all`,
    FREE_DNS_STATISTICS: `${HOST}admin/free_dns/statistics`,
    FREE_DNS_RETRY: `${HOST}admin/free_dns/retry`,
    APPS_DEVICES: `${HOST}admin/apps/devices`,
    DEVICE: `${HOST}admin/apps/device`,
    ACTIVATE_APP: `${HOST}admin/apps/devices/activate`,
    FREE_TRIAL: `${HOST}admin/apps/devices/reset_free_trial`,
    DEACTIVATE_APP: `${HOST}admin/apps/devices/deactivate`,
    PLAYLIST: `${HOST}admin/apps/devices/playlists`,
    ADD_PLAYLIST: `${HOST}admin/apps/devices/playlist`,
    DELETE_PLAYLIST: `${HOST}admin/apps/devices/playlist`,
    EDIT_PLAYLIST: `${HOST}admin/apps/devices/playlist`,
    DEVICE_ACTIVATIONS: `${HOST}admin/apps/devices/activation_logs`,
    DEVICE_PAYMNETS: `${HOST}admin/apps/devices/payments`,
    PLAYLISTS: `${HOST}admin/apps/playlist/search`,

    NOTIFICATIONS: `${NOT_HOST}admin`,
    NOTIFICATIONS_FREEZE: `${NOT_HOST}admin/freeze`,
    NOTIFICATIONS_STATISTICS: `${NOT_HOST}admin/statistics`,
    RESELLER_BLOCK: `${HOST}admin/reseller/block`,
    FREE_DNS_DEVICES: `${HOST}admin/free_dns/devices`,
    FREE_DNS_DEVICES_BY_STATISTICS: `${HOST}admin/free_dns/devices/by_statistics`,
};

export default URLS;
